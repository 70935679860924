import { render, staticRenderFns } from "./computing.vue?vue&type=template&id=33b432d1&scoped=true&"
import script from "./computing.vue?vue&type=script&lang=js&"
export * from "./computing.vue?vue&type=script&lang=js&"
import style0 from "./computing.vue?vue&type=style&index=0&id=33b432d1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b432d1",
  null
  
)

export default component.exports