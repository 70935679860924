<template>
	<div class="main" :class="show ? 'h5' : ''">
		<div class="htop">
		<mtop />
		</div>
		<!-- <div class="banner">
				<img
            src="../assets/ximg/banner-dzkf@2x.png"
            alt="szzc"
            class="simg"
          />
		</div> -->
		<div class="save_head">
            <img
            :src="require('../assets/ximg/byy_banner.png')"
            alt="szzc"
            class="mbg"
          />
			<div class="banner">
                <div class="head_title">
                    <img
            :src="require('../assets/ximg/byy.png')"
            alt="szzc"
            class="simg"
          />
                </div>
				<div class="head_titles">基于边缘计算技术，打造优质的云服务平台</div>
				<div class="head_tip">
					通过利用海量碎片化闲置资源构建低成本高品质云服务，
                    比如云游戏/直播/下载/视频加速。</div>
                <div class="head_tip">   用户可以通过路由器、智能硬件、闲置电脑计入到微客赚平台获得现金收益。</div>
				
			</div>
		</div>

		<div class="save_box1">
			<h1 class="box1_title">一站式边缘计算平台解决方案</h1>
			
			<div class="box1_bottom">
				<div class="card">
					<div class="img">
						<img
							src="../assets/ximg/pp1.png"
							alt=""
							srcset=""
						/>
					</div>
                    <div class="right">
					<div class="title">品牌OEM</div>
					<div class="conttxt">
						打造自己的专属品牌
					</div>
                    </div>
				</div>

				<div class="card">
					<div class="img">
						<img
							src="../assets/ximg/pp2.png"
							alt=""
							srcset=""
						/>
					</div>
                    <div class="right">
					<div class="title">技术支持</div>
					<div class="conttxt">订制开发专属功能</div>	
                </div>			
				</div>

				<div class="card">
					<div class="img">
						<img
							src="../assets/ximg/pp3.png"
							alt=""
							srcset=""
						/>
					</div>
                    <div class="right">
					<div class="title">极速部署</div>
					<div class="conttxt">最快当天可用系统</div>	
                </div>			
				</div>
                <div class="card">
					<div class="img">
						<img
							src="../assets/ximg/pp4.png"
							alt=""
							srcset=""
						/>
					</div>
                    <div class="right">
					<div class="title">售后保障</div>
					<div class="conttxt">全天在线有问必答</div>	
                </div>			
				</div>
			</div>
            <h1 class="box1_title">平台介绍</h1>
          
            <div class="box1_bottoms">
                <div class="cards">
					<div class="img">
						<img
							src="../assets/ximg/px1.png"
							alt=""
							srcset=""
						/>
                        <div class="title">自主研发</div>
					</div>
				<div class="conttxt">微客赚平台是自主研发的，基于边缘计算技术做的开放平台。 </div>			
			</div>
            <div class="cards">
					<div class="img">
						<img
							src="../assets/ximg/px2.png"
							alt=""
							srcset=""
						/>
                        <div class="title">具有丰富的开发经验</div>
					</div>
				<div class="conttxt">星润科技拥有10年开发经验，拥有强大的技术团队作为坚实的后盾。 </div>			
			</div>
            <div class="cards">
					<div class="img">
						<img
							src="../assets/ximg/px3.png"
							alt=""
							srcset=""
						/>
                        <div class="title">拥有成熟的SaaS系统</div>
					</div>
				<div class="conttxt">经过市场长时间的检验和系统不断的优化，已经非常完善，仅需一键即可开启属于自己的边缘计算平台。 </div>			
			</div>

            </div>
            <div class="h5mbox" v-if="show">
                <div class="title">WeBox魔盒</div>
                <div class="tips">微客赚魔盒是我们自主生产的硬件设备</div>
                <div class="boxdt" >
                <div class="linex fleft">
                    <img src="../assets/ximg/pic_mohe1.png" alt="" class="snmh">
                <div class="item">
                    <div class="tt">极致的观影体验</div>
                    <img src="../assets/ximg/lin1.png" alt="" class="ine">
                    <div class="cont">拥有4核</div>
                    <div class="cont">CPU2+128G超大内存</div>
                    <div class="cont">4K+HDR高清画质 </div>
                </div>
                <div class="item">
                    <div class="stt">内嵌教育系统</div>
                    <img src="../assets/ximg/lin2.png" alt="" class="ine">
                    <div class="cont m80">从幼儿到高中</div>
                    <div class="cont">覆盖2-18岁年龄</div>
                    <div class="cont">拥有超过350万分钟优质内容库 </div>
                </div>
           
                </div>

                <div class="linex fright">
                    <img src="../assets/ximg/pic_mohe2.png" alt="" class="snmhs">
                    <div class="item ">
                    <div class="tt">辅助孩子教育与学习的最佳工具</div>
                    <img src="../assets/ximg/lin4.png" alt="" class="ine">
                    <div class="cont">丰富的内容，有趣的节目，</div>
                    <div class="cont">生动的讲解和风趣的语言，</div>
                    <div class="cont">是辅助孩子教育与学习的最佳工具之一。</div>
                </div>
                <div class="item ">
                    <div class="stt">同步99%以上主流版本教材课</div>
                    <img src="../assets/ximg/lin3.png" alt="" class="ine">
                    <div class="cont m80">小学到高中的学习内容更加系统全面，</div>
                    <div class="cont">同步99%以上主流版本教材课程，</div>
                    <div class="cont">适用全国。</div>
                </div>
                </div>
            </div>
                
            </div>
            <div class="mbox" v-else>
                <div class="title">WeBox魔盒</div>
                <div class="tips">微客赚魔盒是我们自主生产的硬件设备</div>
               
             
                <div class="boxdt" >
                <div class="linex">
                <div class="item">
                    <div class="tt">极致的观影体验</div>
                    <img src="../assets/ximg/lin1.png" alt="" class="ine">
                    <div class="cont">拥有4核</div>
                    <div class="cont">CPU2+128G超大内存</div>
                    <div class="cont">4K+HDR高清画质 </div>
                </div>
                <div class="item txr">
                    <div class="tt">辅助孩子教育与学习的最佳工具</div>
                    <img src="../assets/ximg/lin4.png" alt="" class="ine">
                    <div class="cont">丰富的内容，有趣的节目，</div>
                    <div class="cont">生动的讲解和风趣的语言，</div>
                    <div class="cont">是辅助孩子教育与学习的最佳工具之一。</div>
                </div>
                </div>

                <div class="linex">
                <div class="item">
                    <div class="stt">内嵌教育系统</div>
                    <img src="../assets/ximg/lin2.png" alt="" class="ine">
                    <div class="cont">从幼儿到高中</div>
                    <div class="cont">覆盖2-18岁年龄</div>
                    <div class="cont">拥有超过350万分钟优质内容库 </div>
                </div>
                <div class="item txr">
                    <div class="stt">同步99%以上主流版本教材课</div>
                    <img src="../assets/ximg/lin3.png" alt="" class="ine">
                    <div class="cont">小学到高中的学习内容更加系统全面，</div>
                    <div class="cont">同步99%以上主流版本教材课程，</div>
                    <div class="cont">适用全国。</div>
                </div>
                </div>
            </div>
            </div>
           



            <h1 class="box1_title">专属OEM</h1>
            <h2 class="box1_tip">为企业提供技术支持、生产支持、培训支持、售后支持等一系列优质服务。</h2>
            <div class="zsbox">
                <div class="sitem">
                    <div class="tt1">微客赚作为边缘计算</div>
                    <div class="tt2">引领者之一</div>
                    <div class="line"></div>
                    <div class="cont">可以帮助企业对接上游业务：金山云，七牛云，腾讯云等；</div>
                    <div class="cont">帮助企业定制硬件设备：X86，小盒子，软路由等；</div>
                    <div class="cont">帮助企业提供相应资质，提供专业的售后指导，培训指导，帮助企业迅速跑上CDN业务赛道。</div>
                </div>
                <div class="sitem">
                    <div class="tt1">快速部署，一键开启CDN业务支持</div>
                    <div class="tt2">相关需求</div>
                    <div class="line"></div>
                    <div class="cont">快速部署独立品牌系统，解决开发难题；</div>
                    <div class="cont"> 为企业提供定制化开发服务；</div>
                    <div class="cont">为企业的宣传作出快速推进的系统解决方案。</div>
                </div>
            </div>
			
		</div>

		

		<div class="save_box4">
			<h1 class="box4_title">定制服务流程</h1>
			<h2 class="box4_tip">按需定制，快速响应客户定制需求</h2>
			<div class="box4_bot">
				<div class="line">
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqdy.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">需求调研</div>
						<div class="tip2">01</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqfx.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">需求分析</div>
						<div class="tip2">02</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-yxsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">原型设计</div>
						<div class="tip2">03</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-cpsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">产品设计</div>
						<div class="tip2">04</div>
					</div>
				</div>
				<div class="bank">
					<div class="zzimg">
						<img src="../assets/ximg/jt.png" alt="" />
					</div>
				</div>
				<div class="line resco">
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqdy.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">界面设计</div>
						<div class="tip2">05</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqfx.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">程序编码</div>
						<div class="tip2">06</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-yxsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">软件测试</div>
						<div class="tip2">07</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-cpsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">打包发布</div>
						<div class="tip2">08</div>
					</div>
				</div>
			</div>
			<div class="bottom">
				<div class="btn" @click="toPage('/customizing')">立即定制</div>
			</div>
		</div>

		<mbottom />
	</div>
</template>

<script>
	import Swiper from "swiper";
	import "swiper/swiper.css";
	import mtop from "../components/top.vue";
	import mbottom from "../components/bottom.vue";
	export default {
		data() {
			// this.$router.replace({ path: "X3matrix" });
			return {
				current: 0,
				autoplay: true,
				index: 0,
				banbox: [],
				problem: [],
				umobile: "",
				buy: true,
				buynum: 1,
				show: false,
				shows: false,
				shloading: false,
				sqloading: false,
				list: [
					{
						title: "网站建设周期需要多久？",
						description:
							"一般做个企业官网建设，周期为10-15个工作日，功能定制型网站，根据定制要求，核算交付周期。",
					},
					{
						title: "网站建设不满意怎么办？",
						description:
							"网站设计师按要求出设计稿，初稿不满意，可根据贵司的要求修改，并且不限制修改次数，直到满意为止！",
					},
					{
						title: "你们可以移交源代码嘛？",
						description:
							"可以的，我司在核实完您的信息之后，承诺随时提供网站建设源码和FTP信息，FTP信息很重要，请您妥善保管。",
					},
					{
						title: "外地企业怎么和你们沟通?",
						description:
							"可以通过QQ、微信、电话、视频会议等进行沟通，无需担心，不管本地及外地，项目进行中都是在线沟通的！",
					},
					{
						title: "已经有域名了，需要提供给你们什么？",
						description:
							"您只需要提供域名解析权限或管理密码就可以，我公司技术会帮您解析好IP地址，完善服务器信息！",
					},
					{
						title: "产品图片是你们帮我处理吗？",
						description:
							"我公司会帮您处理产品图（20张以内），banner图设计3-5张，如有特殊需要，请联系一诺客服！",
					},
					{
						title: "企业网站建设要多少钱？",
						description:
							"一般企业网站建设而言，如果只是展示宣传，无特殊功能，1万五千元左右，如有特殊定制，功能需求等需另行核算。",
					},
					{
						title: "你们能做网站优化推广吗？",
						description:
							"SEO是重要的引流方式，我们有多种推广方式，网站优化，竞价排名，SEM等供您选择，少花钱多办事儿！",
					},
					{
						title: "网站、APP开发有合同吗？",
						description:
							"签订正规网站建设合同，开具发票，诚信合作。",
					},
					{
						title: "是否有其他隐形费用?",
						description:
							"重合同、守承诺，网站建设期间无任何隐形费用。",
					},
				],
			};
		},
		focus: {
			inserted: function (el, { value }) {
				console.log(el, { value });
				if (value) {
					el.focus();
				}
			},
		},

		components: {
			mtop,
			mbottom,
		},

		mounted() {
			if (this._isMobile()) {
				this.show = true;
			} else {
				this.show = this.$store.getters.phone;
			}
			this.initCase();
		},
		watch: {
			"$store.getters.phone": function (val) {
				this.show = val;
			},
			deep: true,
			immediate: true,
		},
		created() {
			this.getinfo();
		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
			outleft() {
				this.autoplay = true;
			},
			moveleft(val) {
				console.log(val);
				this.autoplay = false;
				this.index = val;
			},
			changeban(val) {
				console.log(val);
				this.index = val;
			},
			kefu() {
				//   console.log(1111);
				//    window._MICHAT = window._MICHAT || function () { (_MICHAT.a = _MICHAT.a || []).push(arguments) };
				// _MICHAT("accountid", 127020);
				// _MICHAT("domain", "mamkf.kanuomei.com");
				// (function (m, d, q, j, s) {
				//     j = d.createElement(q),s = d.getElementsByTagName(q)[0];
				//     j.async = true;
				//     j.charset ="UTF-8";
				//     j.src = ("https:" == document.location.protocol ? "https://" : "http://") + "mamkf.kanuomei.com/Web/JS/mivisit.js?_=t";
				//     s.parentNode.insertBefore(j, s);
				// })(window, document, "script");
			},
			toPage(row) {
				this.$router.push(row);
			},
			getprice() {
				if (this.umobile == "") {
					this.$toast("请填写正确手机号!");
					return false;
				} else {
					this.$axios({
						method: "post",
						url: "/v1/apply",
						params: { mobile: this.umobile },
					})
						.then((res) => {
							console.log(res);
							if (res.data.code == 200) {
								this.$toast("成功提交");
								this.umobile = "";
							} else {
								this.$toast(res.data.msg);
							}
						})
						.catch((resp) => {
							this.$toast(resp.data.msg);
						});
				}
			},

			getinfo() {
				//   this.$axios({
				//     method:'get',
				//     url:'/v1/articleList',
				//     params:{type:2,cate_id:1,is_hot:1},
				// }).then((res)=>{
				//     this.list=res.data.data.list.data;
				// }).catch(resp => {
				//     console.log('请求失败');
				// });
			},
			to(e) {
				// this.$router.replace({ path: e });
				location.href = e;
			},
			tobuy() {
				location.href =
					"https://opensea.io/collection/cupid-love-metaverse";
			},

			close() {
				this.shows = false;
			},
			initCase() {
				//一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
				new Swiper("#case", {
					loop: false,
					breakpoints: {
						320: {
							//当屏幕宽度大于等于320
							slidesPerView: 4,
						},
						750: {
							//当屏幕宽度大于等于768
							slidesPerView: 5,
						},
					},
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	$t-mf: "Arial";
	.case-tab {
		display: flex;
		justify-content: center;
		border-bottom: 1px solid #dcdee0;
		.case-nav {
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			color: #646566;
			padding: 10px 0;
			cursor: pointer;
			&.active {
				font-size: 16px;
				font-weight: 500;
				color: #2b6bff;
				border-bottom: 1px solid #2b6bff;
			}
		}
	}
	.main {
		background: #fff;
		max-width: 100%;
		min-width: 1200px;
		width: 100%;
		.htop{
		    height: 70px;
	    }	
        .banner{
    width: 100%;
    font-size: 0;
    .simg{
        width: 100%;
        height: auto;
    }
  }
		.save_head {
			cursor: default;
			height: 527px;
			// background: url("../assets/ximg/byy_banner.png") no-repeat;
			// background-size: 100% 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
            position: relative;
        
            .mbg{
                min-width: 1920px;
                width: 100%;
                height: auto;
                position: absolute;
                top: 0;
                left: 0;
            }
			.banner {
				width: 1200px;
				margin: 100px auto 10px;
                z-index: 1;
                background: transparent;
                position: relative;
			}
            .head_title {
				width: 1200px;
                font-size: 68px;
                font-family: YouSheBiaoTiHei;
                color: #101E42;
                line-height: 88px;
				text-align: left;
				padding-left: 40px;
                .simg{
                    width: 438px;
                    height: auto;
                }
			}
			.head_titles {
				width: 1200px;
				font-size: 28px;
                color: #101E42;
                line-height: 36px;
				text-align: left;
				padding-left: 40px;
                margin-bottom: 24px;
			}

			.head_tip {
				font-size: 20px;
                color: #8E8E8F;
                line-height: 40px;
				padding-left: 40px;
				text-align: left;
                width: 501px;
			}
		}
		.save_box1 {
			cursor: default;
			width: 100%;
			padding: 90px 0;
			background: linear-gradient(180deg, #F4F6FE 0%, #FFFFFF 100%);
			display: flex;
			// height: 702px;
			box-sizing: border-box;
			flex-direction: column;
			text-align: center;
			.box1_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin: 0px;
			}
			.box1_tip {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				margin: 0px;
				line-height: 50px;
			}
			.box1_bottom {
				display: flex;
				justify-content: space-between;
				width: 1200px;
                background: #FFFFFF;
                box-shadow: 0px 2px 20px 0px #F3F4FA;
                border-radius: 20px;
                border: 1px solid #ECEDF2;
				margin: 36px auto;
                padding: 0 60px;
                box-sizing: border-box;
				.card {
					padding: 50px 0;
					border-radius: 2px;
					display: flex;
					flex-direction: row;
					align-items: center;
					overflow: hidden;
					.img {
						img {
							width: 80px;
							height: 60px;
						}
					}
                    .right{
                        text-align: left;
                    }
					.title {
						font-size: 24px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #1d2129;
						line-height: 33px;
						margin-bottom: 6px;
					}
					.conttxt {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #969799;
					}
					.btn {
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #2b6bff;
						line-height: 40px;
						width: 112px;
						height: 40px;
						background: #ffffff;
						border-radius: 2px;
						border: 1px solid #2b6bff;
						margin-top: 50px;
						display: none;
					}
				}
				// .card:hover {
				// 	box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
				// 	border-radius: 2px;
				// 	height: auto;
				// 	transition: all 0.8s ease-in;
				// 	.btn {
				// 		display: block;
				// 		cursor: pointer;
				// 	}
				// }
			}
            .box1_bottoms{
                display: flex;
				justify-content: space-between;
                align-items: center;
                margin: 36px auto;
                width: 1200px;
                .cards{
                    width: 374px;
height: 208px;
background: #FFFFFF;
box-shadow: 0px 2px 20px 0px #F3F4FA;
border-radius: 20px;
border: 1px solid #ECEDF2;
padding: 32px 57px;
box-sizing: border-box;
                    .img{
                        display: flex;
                        align-items: center;
                        margin-bottom: 21px;
                        img{
                            width:36px;
                            height: 36px;
                        }
                        .title{
                            font-size: 20px;
                            color: #1D2129;
                            line-height: 27px;
                            margin-left: 12px;
                        }
                    }
                    .conttxt{
                        padding-top: 14px;
                        border-top: 1px solid #E4E6F0;
                        font-size: 16px;
                        color: #646566;
                        line-height: 28px;
                        text-align: left;
                    }

                }
            }
            .mbox{
            background: url("../assets/ximg/pic_mohe.png") no-repeat;
			background-size: 100% 100%;
            width: 1200px;
            margin: 0 auto 56px;
            min-height: 580px;
            box-sizing: border-box;
            padding: 41px 88px;
            display: flex;
                    align-items: center;
                    flex-direction: column;
                .title{
                font-size: 32px;
                color: #FFFFFF;
                line-height: 43px;
                }
                .tips{
                    font-size: 16px;
                    color: #9AA5B2;
                    line-height: 28px;
                }
                .boxdt{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    flex: 1;
                }
                .linex{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    .item{
                    .tt{
                        font-size: 20px;
                        color: #5A97FF;
                        line-height: 28px;
                        text-align: left;
                        margin-bottom: 6px;
                        // padding-bottom:16px ;
                    //    border-bottom: 1px solid #fff;
                    //    border-image: linear-gradient(270deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) 1 1;
                    }
                    .stt{
                        font-size: 20px;
                        color: #5A97FF;
                        line-height: 28px;
                        text-align: left;
                        margin-bottom: -6px;
                    }
                    .ine{
                        width: 320px;
                        height: 20px;
                    }
                    .cont{
                        font-size: 16px;
                        color: rgba(255,255,255,0.8);
                        line-height: 28px;
                        text-align: left;
                    }
                   
                    }
                    .item:hover{
                        .cont{
                        opacity: 0.8;
                        }
                    }
                    .txr{
                       .stt, .tt{
                            text-align: right;
                        }
                        .cont{
                            text-align: right;
                        }
                    }
                }
                
            }

            .h5mbox{
                width: 100%;
                min-height: 1480px;
                background: #061321;
                margin-bottom: 28px;
                padding: 36px 29px;
                box-sizing: border-box;
                overflow: hidden;
                .title{
                font-size: 36px;
                color: #FFFFFF;
                line-height: 48px;
                margin-bottom: 8px;
                }
                .tips{
                    font-size: 20px;
                    color: #9AA5B2;
                    line-height: 28px;
                }
                .boxdt{
                    .linex{
                        display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    width: 100%;
                    min-height: 500px;
                    position: relative;
                    margin: 60px 0;
                    .snmh{
                        width: 877px;
                        height: auto;
                        position: absolute;
                        top: -2%;
                        right: -50%;
                    }
                    .snmhs{
                        width: 580px;
                        height: auto;
                        position: absolute;
                        top: 21%;
                        left: -5%;
                    }
                    .item{
                        position: relative;
                        z-index: 10;
                        margin-top: 66px;
                    .tt{
                        font-size: 28px;
                        color: #5A97FF;
                        line-height: 28px;
                        text-align: left;
                        margin-bottom: 23px;
                        // padding-bottom:16px ;
                    //    border-bottom: 1px solid #fff;
                    //    border-image: linear-gradient(270deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) 1 1;
                    }
                    .stt{
                        font-size: 28px;
                        color: #5A97FF;
                        line-height: 28px;
                        text-align: left;
                        margin-bottom: -6px;
                    }
                    .ine{
                        width: 478px;
                        height: auto;
                    }
                    .cont{
                        font-size: 24px;
                        color: rgba(255,255,255,0.8);
                        // line-height: 40px;
                        margin-bottom: 10px;
                        text-align: left;
                    }
                    .m80{margin-top: 23px;}
                   
                    }
                    }
                    .fleft{
                        align-items: flex-start;
                    }
                    .fright{
                        align-items: flex-end;
                        .item{
                        .stt, .tt{
                            text-align: right;
                        }
                        .cont{
                            text-align: right;
                        }
                    }
                    }
                }
            }
            .zsbox{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 1200px;
                margin: 36px auto;
                .sitem{
                    width: 584px;
                height: 314px;
                background: linear-gradient(123deg, rgba(255,255,255,0.44) 0%, rgba(255,255,255,0) 8%, rgba(244,248,254,0.64) 53%, #F4F8FE 88%, rgba(244,248,254,0.78) 100%);
                border: 1px solid #F4F8FE;
                padding: 49px 33px;
                box-sizing: border-box;
              
                .tt1{
                    font-size: 18px;
                    color: #1D2129;
                    line-height: 24px;
                    text-align: left;
                    font-weight: 600;
                }
                .tt2{
                    font-size: 24px;
                    color: #1D2129;
                    text-align: left;
                    line-height: 32px;
                    background: linear-gradient(117deg, #3970FF 0%, #34AFFF 100%, #0A87FD 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-top: 10px;
                }
                .line{
                    width: 30px;
                    height: 2px;
                    background: #E4E6F0;
                    margin: 16px 0;
                }
                .cont{
                    text-align: left;
                    font-size: 16px;
                    color: #646566;
                    line-height: 28px;
                    width: 449px;
                }
            }
            .sitem:hover{
                box-shadow: 0px 0px 16px 0px #EDEEF2;
            }
            }


		}
		.save_box2 {
			cursor: default;
			// width:1920px;
			min-height: 626px;
			background: #ffffff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 90px 0;
			width: 1200px;
			margin: 0 auto;
			.box2_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin: 0 auto;
				width: 100%;
				text-align: center;
			}
			.box2_tip {
				width: 100%;
				margin: 0 auto;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				text-align: center;
				line-height: 50px;
			}
			.box2_bot {
				display: flex;
				width: 1200px;
				margin: 20px auto;
				justify-content: space-between;
				flex-direction: column;
				.bot_item {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background: #ffffff;
					box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
					border-radius: 2px;
					margin: 22px 0;
					padding: 12px 73px;
					box-sizing: border-box;
					.left {
						img {
							width: 433px;
							height: auto;
						}
					}
					.right {
						width: 476px;
						.title {
							font-size: 24px;
							font-weight: 500;
							color: #1d2129;
							line-height: 33px;
							text-align: left;
							margin: 30px 0;
							position: relative;
							&::after {
								content: "";
								width: 22px;
								height: 4px;
								background: #2b6bff;
								border-radius: 2px;
								position: absolute;
								left: 0;
								bottom: -15px;
							}
						}
						.sinfo {
							font-size: 14px;
							font-weight: 400;
							color: #969799;
							text-align: left;
						}
					}
				}
				.bot_item:nth-child(2n) {
					flex-direction: row-reverse;
				}

				.item2 {
					.item_head {
						width: 100%;
						height: 87px;
						text-align: center;
						background: linear-gradient(
							264deg,
							#fee2cd 0%,
							#fff2e7 100%
						);
						box-sizing: border-box;
						padding: 20px;

						div {
							color: #8b3b34;
						}
						div:nth-child(1) {
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 30px;
						}
						div:nth-child(2) {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 20px;
						}
					}
					.item_con {
						box-sizing: border-box;
						padding: 20px;
						.con_item {
							display: flex;
							justify-content: space-between;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
							line-height: 30px;
							span {
								color: #8b3b34;
								font-size: 18px;
							}
						}
					}
					.item_bot {
						width: 186px;
						height: 41px;
						background: #8b3b34;
						border-radius: 4px;
						text-align: center;
						margin: 0 auto;
						line-height: 41px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						span {
							font-size: 10px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffffff;
							line-height: 20px;
						}
					}
				}

				.item3 {
					.item_head {
						width: 100%;
						height: 87px;
						text-align: center;
						background: linear-gradient(
							92deg,
							#eff8ff 0%,
							#d0e7f9 100%
						);
						box-sizing: border-box;
						padding: 20px;

						div {
							color: #0c63d7;
						}
						div:nth-child(1) {
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;

							line-height: 30px;
						}
						div:nth-child(2) {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							line-height: 20px;
						}
					}
					.item_con {
						box-sizing: border-box;
						padding: 20px;
						.con_item {
							display: flex;
							justify-content: space-between;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
							line-height: 30px;
							span {
								color: #0c63d7;
								font-size: 18px;
							}
						}
					}
					.item_bot {
						width: 186px;
						height: 41px;
						background: #ffffff;
						border: 1px solid #0c63d7;
						color: #0c63d7;
						border-radius: 4px;
						text-align: center;
						margin: 0 auto;
						line-height: 41px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #0c63d7;
						span {
							font-size: 10px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #0c63d7;
							line-height: 20px;
						}
					}
				}

				.item4 {
					.item_head {
						width: 100%;
						height: 87px;
						text-align: center;
						background: linear-gradient(
							264deg,
							#fec0ae 0%,
							#ffe0d7 100%
						);
						box-sizing: border-box;
						padding: 20px;
						color: #ff8460;
						div {
							color: #ff8460;
						}
						div:nth-child(1) {
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 30px;
						}
						div:nth-child(2) {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 20px;
						}
					}
					.item_con {
						box-sizing: border-box;
						padding: 20px;
						.con_item {
							display: flex;
							justify-content: space-between;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
							line-height: 30px;
							span {
								font-size: 18px;
								color: #ff8460;
							}
						}
					}
					.item_bot {
						width: 186px;
						height: 41px;
						background: #ff8460;
						border-radius: 4px;
						text-align: center;
						margin: 0 auto;
						line-height: 41px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						a {
							color: #ffff;
							span {
								font-size: 10px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #ffffff;
								line-height: 20px;
							}
						}
					}
				}
			}
		}
		.save_box3 {
			cursor: default;
			width: 100%;
			height: 578px;
			background: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.box3_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				width: 1200px;
				margin: 0 auto;
			}
			.box3_con {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				width: 1200px;
				margin: 0 auto;
				line-height: 50px;
			}
			.box3_bot {
				width: 1200px;
				margin: 40px auto;
				padding: 0 30px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				.bot_item {
					width: 234px;
					height: 300px;
					background: #ffffff;
					box-shadow: 0px 0px 21px 0px rgba(81, 81, 81, 0.19);
					border-radius: 4px;
					border-bottom: 3px solid #0fb3b4;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					box-sizing: border-box;
					padding: 0 30px;
					div:nth-child(1) {
						font-size: 26px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #0fb3b4;
						line-height: 30px;
					}
					div:nth-child(2) {
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 30px;
						margin-top: 40px;
						text-align: center;
					}
					div:nth-child(3) {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #7e8184;
						line-height: 30px;
						text-align: left;
						margin-top: 27px;
						text-align: center;
					}
				}
				.you1 {
					border-bottom: 3px solid #0fb3b4;
					div:nth-child(1) {
						font-size: 26px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #0fb3b4;
						line-height: 30px;
					}
				}
				.you2 {
					border-bottom: 3px solid #8b3b34;
					div:nth-child(1) {
						color: #8b3b34;
					}
				}
				.you3 {
					border-bottom: 3px solid #16d1f1;
					div:nth-child(1) {
						color: #16d1f1;
					}
				}
				.you4 {
					border-bottom: 3px solid #ff8460;
					div:nth-child(1) {
						color: #ff8460;
					}
				}
				.bot_item:hover {
					color: #fff !important;
					background: linear-gradient(0deg, #1a8eff 0%, #19d5f5 100%);
					box-shadow: 0px 0px 21px 0px rgba(26, 150, 254, 0.19);
					border-radius: 10px;
					transform: scale(1.1);
					div {
						color: #fff !important;
					}
				}
			}
		}
		.save_box4 {
			height: 589px;
			background: #f4f6fe;
			padding: 1px;
			width: 100%;
			margin: 0 auto;
			.box4_title {
				width: 100%;
				margin: 0 auto;
				text-align: center;
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin-top: 54px;
			}
			.box4_tip {
				width: 100%;
				margin: 0 auto;
				text-align: center;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				margin-top: 15px;
			}
			.box4_bot {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 1200px;
				margin: 0 auto;
				margin-top: 25px;
				.line {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.jtico {
						width: 45px;
						height: 45px;
						margin: 0 11px;
					}
				}
				.resco {
					flex-direction: row-reverse;
					.jtico {
						transform: rotate(180deg);
					}
				}
				.bank {
					display: flex;
					justify-content: flex-end;
					width: 100%;
					.zzimg {
						width: 249px;
						height: 75px;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					img {
						width: 45px;
						height: 45px;
						transform: rotate(90deg);
					}
				}
				.box_item {
					width: 249px;
					height: 75px;
					background: #ffffff;
					border-radius: 2px;
					display: flex;
					align-items: center;
					justify-content: center;
					box-sizing: border-box;
					position: relative;
					.img1 {
						width: 25px;
						height: 25px;
					}
					.tip1 {
						font-size: 18px;
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #1d2129;
						line-height: 27px;
						margin-left: 15px;
					}
					.tip2 {
						position: absolute;
						bottom: 0;
						right: 0;
						font-size: 50px;
						font-family: DINPro-Bold, DINPro;
						font-weight: bold;
						color: #f7f8fa;
						line-height: 65px;
					}
				}

				.box_item:hover {
					transform: scale(1.02);
					box-shadow: 0px 0px 0.28rem 0px rgb(0 0 0 / 19%);
				}
			}
			.bottom {
				width: 100%;
				display: flex;
				justify-content: center;
				margin: 59px 0;
				text-align: center;
				.btn {
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #ffffff;
					line-height: 40px;
					width: 112px;
					height: 40px;
					background: #2b6bff;
					border-radius: 2px;
					cursor: pointer;
				}
			}
		}
		.save_box5 {
			// max-height:520px;
			min-height: 300px;
			background: #fff;
			.box5_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #020202;
				width: 1200px;
				margin: 0 auto;
				text-align: center;
				margin-top: 55px;
			}
			.box5-tip {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #020202;
				width: 1200px;
				margin: 0 auto;
				text-align: center;
				margin-top: 13px;
			}
		}
		.box5_look {
			width: 100px;
			height: 34px;
			background: #ffffff;
			border: 1px solid #e9e9e9;
			border-radius: 17px;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
			line-height: 34px;
			margin: 0 auto;
			text-align: center;
			margin-bottom: 35px;
			// margin-top:-38px;
		}
		.box5_look:hover {
			background: linear-gradient(0deg, #1a8eff 0%, #16d1f1 100%);
			color: #fff;
		}
		.box5_bot {
			width: 1200px;
			margin: 0 auto;
			margin-top: 50px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.box5_item {
				width: 590px;
				height: 95px;
				background: #ffffff;
				border: 1px solid #e9e9e9;
				margin-bottom: 20px;
				box-sizing: border-box;
				padding: 15px;
				text-align: left;
				div:nth-child(1) {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
				}
				div:nth-child(2) {
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					line-height: 16px;
					margin-top: 13px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
			.box5_item:hover {
				background: linear-gradient(0deg, #eee 0%, #fff 100%);
			}
		}
	}
	.h5 {
		width: 100%;
		min-width: 100%;
		.htop{
            height:125px;
        }
        .save_head{
            .mbg{
                height: 100%;
                font-size: 0;
                margin: 0 auto;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .banner{
    width: 100%;    
    font-size: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    .simg{
        width: 1000px;
        height: auto;
    }
  }
		.save_head {
			.banner {
				width: 100%;
				margin: 0;
				.head_title {
					width: 100%;
					padding: 0 40px;
					box-sizing: border-box;
				}
                .head_titles{
                    width: 100%;
					padding: 0 40px;
					box-sizing: border-box;
                }
				.head_tip {
					width: 100%;
					font-size: 22px;
					padding: 0 40px;
					box-sizing: border-box;
				}
			}
		}
		.save_box1 {
			width: 100%;
			height: auto;
			text-align: center;
			.box1_title {
				font-size: 36px;
				font-weight: 600;
			}
			.box1_tip {
				font-size: 26px;
				margin: 20px auto;
			}
			.box1_bottom {
				flex-wrap: wrap;
				width: auto;
                margin: 32px;
                box-sizing: border-box;
                padding: 0;
				.card {
					width: calc(50% - 60px);
					height: auto;
					margin: 20px auto;
                    padding: 26px 0;
					.img {
						img {
							width: 80px;
							height: 60px;
						}
					}
					.title {
						font-size: 26px;
					}
					.conttxt {
						line-height: 36px;
						font-size: 22px;
                        white-space: nowrap;
					}
					.btn {
						width: 200px;
						height: 68px;
						font-size: 30px;
						line-height: 68px;
					}
				}
			}
            .box1_bottoms{
                flex-direction: column;
                width: 100%;
                .cards{
                    width: 686px;
                    margin: 12px;
                    .img{
                        img{width: 36px;height: 36px;}
                        .title{
                        font-size: 26px;
                        font-weight: 600;
                    }
                    }
                   
                    .conttxt{
                        font-size: 22px;
                        line-height: 32px;
                    }
                }
            }
            .zsbox{
                flex-direction: column;
                width: 100%;
                .sitem{
                    margin: 15px auto;
                    width: 686px;
                    height: 100%;
                    background: linear-gradient(320deg, rgba(255, 255, 255, 0.44) 0%, rgba(255, 255, 255, 0) 8%, rgba(244, 248, 254, 0.64) 53%, #F4F8FE 88%, rgba(244, 248, 254, 0.78) 100%);
                    box-shadow: 0px 0px 16px 0px #EDEEF2;
                    .tt1{
                        font-size: 26px;
                    }
                    .tt2{
                        font-size: 40px;
                        line-height: 80px;
                    }
                    .line{
                        width: 35px;
                        height: 2px;
                        background: #E4E6F0;
                    }
                    .cont{
                        font-size: 22px;
                        width: 100%;
                        line-height: 42px;
                        white-space: normal;
                        word-break: break-all;
                    }
                }
            }
		}
		.save_box2 {
			width: 100%;
			.box2_title {
				font-size: 36px;
				font-weight: 600;
			}
			.box2_tip {
				font-size: 26px;
				margin: 20px auto;
			}
			.box2_bot {
				width: 100%;
				.bot_item {
					flex-direction: column;
					padding-bottom: 40px;
					.left {
						img {
							width: calc(100% - 60px);
							height: 100%;
							margin: 0 30px;
						}
					}
					.right {
						.title {
							font-size: 32px;
						}
						.sinfo {
							font-size: 26px;
						}
					}
				}
				.bot_item:nth-child(2n) {
					flex-direction: column;
				}
			}
		}
		.save_box4 {
			width: 100%;
			height: 100%;
			.box4_title {
				font-size: 36px;
				font-weight: 600;
			}
			.box4_tip {
				font-size: 26px;
				margin: 20px auto;
			}
			.box4_bot {
				width: 100%;
				margin: 60px auto;
				.line {
					flex-direction: column;
					width: 80%;
					margin: 0 auto;
					.box_item {
						width: 100%;
						flex-direction: column;
						height: 100%;
						padding: 20px;
						border-radius: 10px;
						.tip1 {
							margin-left: 0;
							font-size: 26px;
						}
						.img1 {
							width: 66px;
							height: 66px;
							margin: 20px 0;
						}
					}
					.jtico {
						transform: rotate(90deg);
						margin: 20px;
						height: 100px;
						width: 100px;
					}
				}
				.bank {
					justify-content: center;
					.zzimg {
						height: 100px;
						margin: 20px;
						img {
							height: 100px;
							width: 100px;
						}
					}
				}
			}
			.bottom {
				.btn {
					width: 300px;
					height: 100px;
					line-height: 100px;
					font-size: 30px;
					border-radius: 10px;
				}
			}
		}
	}
</style>
